import React from 'react';
import {observable, action, decorate} from 'mobx';
import {isEmpty, isNumber, noop} from 'lodash-es';
import {userDB, videodb, videoDBbaseURL} from 'layouts/Admin.jsx';

export class dataStore {

  data = observable([]);
  userdata = observable([]);
  orders = observable([]);
  activities = observable([]);
  filtereddata = observable([]);
  filterstring = null;
  
  fetchData = () => videodb.get('/dashboard')
  .then(res => this.data = res.data);

  fetchUserData = () => videodb.get('/user/me')
  .then(res =>
    {
      this.getUser();
      this.userdata.avatar = res.data.avatar;
      this.userdata.logo = res.data.logo
    }
  )

  getData = () => {
    if(!isEmpty(this.data)){
      return new Promise((resolve, reject) => resolve(this.data))
    }else{
      return this.fetchData();
    }
  }

  getUserData = () => { 
    if(!isEmpty(this.userdata)){
      return new Promise((resolve, reject) => resolve(this.userdata))
    }else{
      return this.fetchUserData();
    }
}

getUser = () => userDB.get('/auth/user/')
.then(user => {
  this.userdata.type = user.data.user_type;
  this.userdata.email = user.data.email;
  this.userdata.firstname = user.data.first_name;
  this.userdata.lastname = user.data.last_name;
  this.userdata.team = user.data.team;
})


  setFilterString = string => this.filterstring = string;

  filterData = string => {
   this.filtereddata = this.data.scripts.filter(scripts => scripts.title.toLowerCase().includes(string.toLowerCase()));
}

getActivities = renew => {
  if(renew)  return this.fetchActivities();
  if(!isEmpty(this.activities)){
    return new Promise((resolve, reject) => resolve(this.activities))
  }else{
    return this.fetchActivities();
  }
}

getOrders = renew => {
  console.log('getting orders..');
  if(renew)  return this.fetchOrders();
  if(!isEmpty(this.orders)){
    return new Promise((resolve, reject) => resolve(this.orders))
  }else{
    return this.fetchOrders();
  }
}

getInvoices = renew => {
  if(renew)  return this.fetchInvoices();
  if(!isEmpty(this.invoices)){
    return new Promise((resolve, reject) => resolve(this.invoices))
  }else{
    return this.fetchInvoices();
  }
}

fetchInvoices = () => userDB.get('/invoice')
.then(res => this.invoices = res.data)

fetchActivities = () => videodb.get('/dashboard/activities')
.then(res => this.activities = res.data.activities);


fetchOrders = () => videodb.get('/dashboard/orders')
.then(res => this.orders = res.data.orders || null);
} //do orders

export default dataStore;