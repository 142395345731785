import React, { Component } from "react";
import {observer, inject} from 'mobx-react';
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table } from "react-bootstrap";
import {isEmpty} from 'lodash-es';
import Datatable from 'react-bs-datatable';
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {pick} from 'lodash-es';
import avatar from "assets/img/faces/face-3.jpg";
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Orders } from "components/Orders/Orders.jsx";
import  Spinner from "components/Spinner/index.js"
import {videodb, _token, videoDBbaseURL} from 'layouts/Admin.jsx';
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "variables/Variables.jsx";
import classNames from 'classnames';
import styles from './viewstyles.module.scss';
import profile from "assets/profile.png";
import webappimg from "assets/img/webapp.png";
//import tutorialsimg from "assets/tutorials.jpg";

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      size: [],
      stats: [],
      userdata: [],
      data: [],
      orders: [],
      numvlogs: null,
      numvids: null,
      loadingstats: true,
      loadingnumvlogs: true,
      loadingactivities: true,
      loadinguserdata: true,
      loadingorders: true
    }
  }

  componentDidMount(){
    this.getstats();
    this.getnumvlogs();
    this.props.dataStore.getActivities().then(() => {
       this.setState({data: this.props.dataStore.activities})
       this.setState({loadingactivities: false})
    });
    this.props.dataStore.getUserData().then(() => { 
      this.setState({userdata: this.props.dataStore.userdata})
      this.setState({loadinguserdata: false})
    });
    this.props.dataStore.getOrders().then(() => { 
      this.setState({orders: this.props.dataStore.orders})
      this.setState({loadingorders: false})
    });
}

getnumvlogs = () => {
  videodb.get('/vlogs')
  .then(res => {
    if(res) this.setState({numvlogs: res.data.vlogs.length});
    this.setState({loadingnumvlogs: false})
  })
}

  getstats = () => {
    videodb.get('/stats')
    .then(res => {
     if(res){
        this.setState({stats:  res.data});
        this.setState({loadingstats: false})
     }
    })
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }



  
    
  
  
        

  render() {
    const stats = this.state.stats || [];
    const size = this.state.stats.size || '-';
    const gb = size.gb || 0;
    const shares = 0;
    const numvlogs = this.state.numvlogs || '0';
    const numvlogslastmonth = stats.vlogs || '0';
    const numvids = this.state.stats.numvids || '0';
    const begindate = this.state.stats.begindate || '1 Jan 2020';
    const activityheader = ['User', 'Activity', 'Date'];
    const data = this.state.data || [];
    const userdata = this.state.userdata;
    const username = (userdata.firstname || '') + ' ' + (userdata.lastname || '');
   const activitycontent2 = data.map(activity =>   {
    return ( {username: activity.username, activity: activity.activity, time: activity.time}
      ) } );

      const activitytableheader = [
        { title: 'Username', prop: 'username', sortable: true, filterable: true  },
        { title: 'Activity', prop: 'activity', sortable: true, filterable: true  },
        { title: 'Time', prop: 'time', sortable: true, isAscending: true, filterable: true  }
      ];
      // const onTableSort = {
      //   time: (value) => {
      //     // This will convert the string to integer.
      //     // Otherwise, the date will be sorted by date-month-year instead of year-month-date (in order).
      //     return moment(value, 'dd-MMM YYYY').valueOf();
      //   }
      // };
      const customLabels = {
        first: '<<',
        last: '>>',
        prev: '<',
        next: '>',
        show: 'Display',
        entries: 'rows',
        noResults: 'There is no (yet) data to be displayed',
        filterPlaceholder: "Filter results..."
      };
      const tableClasses = {
        table: 'table-striped table-hover',
        paginationButton: `${styles.Paginationbtn}`,
        filterClearButton: `${styles.filterbtn}`,
        filterInputGroup: `${styles.filtergroup}`,
        filterFormControl: `fa ${styles.filterinput}`
      };
    return (
      <div className="content">
        <Grid fluid>
          {/* <Row>
            <Col lg={3} sm={6}>
              <Card 
                title="Go to WebApp"
                bgImage={webappimg}
                link={`https://app.vlogahead.com/#/?t=${_token}`}
                category="Open a browser-based version of vlogahead"
                target="_blank"

              />
            </Col>
            {/* <Col lg={3} sm={6}>
            <Card 
                title="Go to tutorials"
              //  bgImage={tutorialsimg}
                link="/admin/tutorials"
              />
            </Col>
            <Col lg={3} sm={6}>
            <Card 
                title="Go to academy"
                link="/admin/academy"
              />
            </Col> 
            <Col lg={3} sm={6}>
            <Card 
                title="Go to Admin"
                category="Change teammembers or account."
                link="https://userdb.vlogahead.cloud"
                target="_blank"
              />
            </Col>
          </Row> */}
          <Row>
            {/* <Col md={8}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Vlogs Created"
                category="Total number of new vlogs"
                stats="Updated 3 minutes ago"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataSales}
                      type="Line"
                      options={optionsSales}
                      responsiveOptions={responsiveSales}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendSales)}</div>
                }
              />
            </Col> */}
            {/* <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Statistics"
                category="Performance"
                stats="Sent 2 days ago"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={dataPie} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendPie)}</div>
                }
              />
            </Col> 
            */}
           <Col md={3}>
                           <StatsCard
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText="Used Diskspace"
                statsValue={gb + " GB"}
                // statsIcon={<i className="fa fa-refresh" />}
                // statsIconText="Updated now"
                // statsIconFunction={this.getstats}
              />
                           <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Vlogs"
                statsValue={numvlogs}
                // statsIcon={<i className="fa fa-calendar-o" />}
                // statsIconText={"Since "+begindate}
                // stat
                />
                           <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-danger" />}
                statsText="Uploaded videos"
                statsValue={numvids}
                // statsIcon={<i className="fa fa-clock-o" />}
                // statsIconText="In the last hour"
              />
                           {/* <StatsCard
                bigIcon={<i className="fa fa-twitter text-info" />}
                statsText="Shares"
                statsValue={shares}
                // statsIcon={<i className="fa fa-refresh" />}
                // statsIconText="Updated now"
              /> */}
             </Col>
            
              
                     
             <Col  lg={3} md={3} sm={6}>
              {
                this.state.loadinguserdata ?
                <UserCard
                description={
                <Spinner small />
                }
              />
                :

              
              <UserCard
                bgImage={userdata.logo || null}
                logo={userdata.logo || null}
                avatar={userdata.avatar || profile}
                name={username || ''}
                userName={userdata.team || ''}
                description={
                  <span><br/>
                    {userdata.email || ''}
                  </span>
                }
                // socials={
                //   <div>
                //     <Button simple>
                //       <i className="fa fa-facebook-square" />
                //     </Button>
                //     <Button simple>
                //       <i className="fa fa-twitter" />
                //     </Button>
                //     <Button simple>
                //       <i className="fa fa-google-plus-square" />
                //     </Button>
                //   </div>
                // }
              />
              }
            </Col>
            <Col md={6}>
              <Card
                title="Orders"
                category="Custom Edits and Custom Subtitles"
                content={
                  this.state.loadingorders ?
                  <Spinner small />
                  :
                  <div className="table-full-width">
                    <table className="table" style={{maxHeight: '200px'}}>
                    { isEmpty(this.state.orders) ? 
                   <tbody><tr><td>No orders found</td></tr></tbody>
                        : 
                      <Orders orders={this.state.orders} />
                    }
                    </table>
                  </div>
                }
              />
            </Col>
          </Row>

          <Row>
            {/* <Col md={6}>
              <Card
                id="chartActivity"
                title="Shares"
                category="All Shares"
                stats="Data information certified"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendBar)}</div>
                }
              />
            </Col> */}

            {/* <Col md={6}>
              <Card
                title="Orders"
                category="Vlogs and Custom Edits"
                stats="Updated 3 minutes ago"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                      <Orders />
                    </table>
                  </div>
                }
              />
            </Col> */}
          </Row>
          {/* <Row>
            <Col md={12}>
              <Card
                title="(Team) Activity Log"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {activityheader.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {activitycontent2.map((prop,key1) =>
                      {
                        return( 
                           <tr key={key1}>
                          {Object.entries(prop).map(([key, value]) => {
                              return <td key={key1 + key + value}>{value}</td>;
                          })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>

          </Row> */}
          <Row>
            <Col md={12}>
              <Card
                title="(Team) Activity Log"
                content={
                this.state.loadingactivities ? 
                 <Spinner small /> : 
                <Datatable tableHeaders={activitytableheader} tableBody={activitycontent2} rowsPerPage={50} labels={customLabels} classes={tableClasses} /> 
                }
              />
            </Col>

          </Row>
        </Grid>
      </div>
    );
  }
}

//export default Dashboard;
export default inject('dataStore')(observer(Dashboard));
