import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import Cookies from 'js-cookie';
import axios from 'axios';
import encode from 'object-to-formdata';

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import { style } from "variables/Variables.jsx";

import routes from "routes.js";

import image from "assets/img/sidebar-3.jpg";

export const userDBbaseURL = 'https://userdb.vlogahead.cloud/api/v1';
export const videoDBbaseURL = 'https://vdb.vlogahead.cloud/api/v1';
export const userDB = axios.create({
  baseURL: userDBbaseURL,
});


export const videodb = axios.create({
  baseURL: videoDBbaseURL,
});

export let _token;
// export const academy = axios.create({
//   baseURL: academybaseURL,
// });

videodb.interceptors.request.use(
  config => ({...config, data: encode(config.data)}),
  error => error
);


videodb.interceptors.response.use(
  response => {
    return response;

  },
  function (error) {
    if (error.response.status === 400) {
      window.location.href = "/admin/login";
    } else if (error.response.status === 401) {
      window.location.href = "/admin/logout";
    } else if (error.response.status === 403) {
      window.location.href = "/admin/login";
    }
  });


class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "hasInlog",
      _notificationSystem: null,
      image: image,
      color: "purple",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open"
    };
    this.user = null;
  }

  initialize = () => {
    //  videodb.get('/login').then(res => {
    //   if(res.data.loggedin){
    //     this.token = res.data.access_token;
      
    // }else{

    try {
      this.token = window.localStorage.getItem('token');
    } catch (error) {
      this.token = Cookies.get('token');
    }
    if (this.token) {
      _token = this.token;
      videodb.interceptors.request.use(
        config => ({...config, headers: {Authorization: `Token ${this.token}`}})
      );
      userDB.interceptors.request.use(
        config => ({...config, headers: {Authorization: `Token ${this.token}`}})
      );
      if(window.location.pathname === "/admin/login") window.location.href = "/admin/dashboard";

    }else{
      window.location.href = "/admin/login";
    }
 // }
//}  );
}


  clearToken = () => {
    this.token = null;
    try {
      window.localStorage.removeItem('token', null);
    } catch (e) {
      Cookies.remove('token');
    }
  }

  convertUserType = typeNumber => {
    switch (typeNumber) {
      case 1: return 'regularUser';
      case 2: return 'teamManager';
      case 3: return 'teamMember';
      default: throw new Error();
    }
  }

  handleNotificationClick = position => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>VlogAhead Dashboard</b> - we will keep updating this dashboard.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...props}
                handleClick={this.handleNotificationClick}
              />
            )}
            key={key}

          />
        );
      } else {
        return null;
      }
    });
  };

  getLayout = (routes) => {
    routes.map((prop) => {
      if(prop.layout + prop.path === window.location.pathname) this.setState({type: prop.type})
      return null
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  componentWillMount() {
    this.getLayout(routes);
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    if(this.state.type === "hasInlog"){
      this.initialize();
      return (
        <div className="wrapper">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Sidebar {...this.props} routes={routes} image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}/>
          <div id="main-panel" className="main-panel" ref="mainPanel">
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
            <Switch>{this.getRoutes(routes)}</Switch>
            <Footer />

          </div>
        </div>
      );
    }else{
      return (
        <div className="wrapper">
  
          <Switch>{this.getRoutes(routes)}</Switch>
  
        </div>
      );
    }
  }
}
export default Admin;
