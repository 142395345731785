import React, {
  Component
} from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import axios from 'axios';
import {
  FormInputs
} from "components/FormInputs/FormInputs.jsx";
import Cookies from 'js-cookie';
import encode from 'object-to-formdata';
import Button from "components/CustomButton/CustomButton.jsx";

import logo from "assets/img/logo-transparent-kopie.png";

export const userDBbaseURL = 'https://userdb.vlogahead.cloud/api/v1';
export const videoDBbaseURL = 'https://vdb.vlogahead.cloud/api/v1';
export const userDB = axios.create({
  baseURL: userDBbaseURL,
});

export const videodb = axios.create({
  baseURL: videoDBbaseURL,
});


videodb.interceptors.request.use(
  config => ({
    ...config,
    data: encode(config.data)
  }),
  error => error
);


videodb.interceptors.response.use(
  response => {
    return response;

  },
  function (error) {
    if (error.response.status === 400) {
      window.location.href = "/admin/login";
    } else if (error.response.status === 401) {
      window.location.href = "/admin/logout";
    } else if (error.response.status === 403) {
      window.location.href = "/admin/login";
    } else {
      window.location.href = "/admin/dashboard";
    }

  });


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
      pending: false
    };
    this.userType = null
    this.csrf_token = null
  }

  componentDidMount() {
    this.getlogin();
  }

  getlogin = () => videodb.get('/login').then(res => {
    if(res.data.loggedin){
      console.log('loggedin');
      this.dologin(res.data.access_token);      
    }else{
      console.log( res.data.token_name);
      this.token_name = res.data.token_name;
      this.csrf_token = res.data.csrf_token;
      this.initialize();
    }
  })

  setProperty = property => e => this.setState({
    [property]: e.target.value
  })

  login = () => videodb.post('/login', {
    email: this.state.email,
    password: this.state.password,
    saveLogin: true,
    token_name: this.token_name,
    csrf_token: this.csrf_token
  }).then(res => {
    let access_token = res.data.access_token;
    this.token = access_token;
    this.dologin(access_token);
  })
   

  dologin = access_token => {
             //we have the accesstoken from the login (vdb) now login at localhost
             videodb.post('/login', {
             email: 'token',
             password: this.token
             }).then(res => {

      
    
    try {
      window.localStorage.setItem('token', access_token);
    } catch (e) {
      Cookies.set('token', access_token);
    }

    this.initialize();

                })
  }

  initialize = () => {
    try {
      this.token = window.localStorage.getItem('token');
    } catch (error) {
      this.token = Cookies.get('token');
    }
    if (this.token) {
      videodb.interceptors.request.use(
        config => ({
          ...config,
          headers: {
            Authorization: `Token ${this.token}`
          }
        })
      );
      userDB.interceptors.request.use(
        config => ({
          ...config,
          headers: {
            Authorization: `Token ${this.token}`
          }
        })
      );
      if (window.location.pathname === "/admin/login") window.location.href = "/admin/dashboard";
    }
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <img src={logo} alt="logo_image" className="logo"></img>
                    
              <FormInputs
                ncols={["col-md-12", "col-md-12"]}
                properties={[
                  {
                    className: "input",
                    label: "Email",
                    type: "text",
                    bsClass: "form-control",
                    defaultValue: this.state.email,
                    onChange: this.setProperty('email')
                  },
                  {
                    className: "input",
                    label: "Password",
                    type: "password",
                    bsClass: "form-control",
                    defaultValue: this.state.password,
                    onChange: this.setProperty('password')
                  }
                ]}
              />

              <Button className="loginButton" bsStyle="info" pullRight fill type="submit" onClick={this.login}>
                Login
              </Button>

              <a className="forgotPass" href="https://userdb.vlogahead.cloud/accounts/password/reset/" target="_blank" rel="noopener noreferrer">
                <p>Forgot password?</p>
              </a>
              <div className="clearfix" />

            </Col>
            
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Login;