import Dashboard from "views/Dashboard.jsx";
import UserProfile from "views/UserProfile.jsx";
import TableList from "views/TableList.jsx";
import Typography from "views/Typography.jsx";
import Icons from "views/Icons.jsx";
import Login from "views/Login.jsx";
import Logout from "views/Logout.jsx";
import Documents from "views/Documents.jsx";
import Notifications from "views/Notifications.jsx";
import Upgrade from "views/Upgrade.jsx";
import CustomVlogs from "views/CustomVlogs.jsx";
import Tutorials from "views/Tutorials.jsx";
import Academy from "views/Academy.jsx";
import Invoices from "views/Invoices.jsx";

const dashboardRoutes = [

  {
    display: false,
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    display: false,
    path: "/user",
    name: "User Profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    display: false,
    path: "/table",
    name: "Table List",
    icon: "pe-7s-note2",
    component: TableList,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    display: false,
    path: "/typography",
    name: "Typography",
    icon: "pe-7s-news-paper",
    component: Typography,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    path: "/documents",
    name: "Documents",
    icon: "pe-7s-news-paper",
    component: Documents,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    display: false,
    path: "/icons",
    name: "Icons",
    icon: "pe-7s-science",
    component: Icons,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    display: false,
    path: "/notifications",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: Notifications,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    path: "/customvlogs",
    name: "My Orders",
    icon: "pe-7s-video",
    component: CustomVlogs,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    path: "/invoices",
    name: "My Invoices",
    icon: "pe-7s-cash",
    component: Invoices,
    layout: "/admin",
    type: "hasInlog"
  },
  {
    path: "/tutorials",
    name: "App Tutorials",
    icon: "pe-7s-help2",
    component: Tutorials,
    layout: "/admin",
    type: "hasInlog"
},
{
  path: "/academy",
  name: "Academy",
  icon: "pe-7s-study",
  component: Academy,
  layout: "/admin",
  type: "hasInlog"
},
  {
    redirect: true,
    path: "/login",
    name: "Login",
    icon: "pe-7s-science",
    component: Login,
    layout: "/admin",
    type: "hasNoInlog"
  },
  {
    redirect: true,
    path: "/logout",
    name: "Logout",
    icon: "pe-7s-science",
    component: Logout,
    layout: "/admin",
    type: "hasNoInlog"
  },


];

export default dashboardRoutes;
