import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import {observer, inject} from 'mobx-react';
import  Spinner from "components/Spinner/index.js"
import Card from "components/Card/Card.jsx";
import { thArray, tdArray } from "variables/Variables.jsx";

class CustomVlogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loadingorders: true
    }
  }


  componentDidMount(){  
    this.props.dataStore.getOrders().then(() => { 
    this.setState({orders: this.props.dataStore.orders})
    this.setState({loadingorders: false})
  });
}

  render() {
    const vlogheader = thArray;
    const vlogdata = tdArray;
    const orders = this.state.orders;
    return (
      <div className="content">
        {this.state.loadingorders ?
        <Spinner small />
        :
        orders ?
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Custom Edited Vlogs"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {vlogheader.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((prop, key) => {
                           let duration = new Date(0);
                           let date = new Date(prop.modified);
                           duration.setSeconds(prop.duration); 
                        
                        return (
                          prop.options.custom_edit &&
                          <tr key={key}>
                            <td>{prop.title}</td>
                           <td>{duration.toISOString().substr(14, 5)}</td>
                           <td>{date.getDate()} - {date.getMonth()} - {date.getFullYear()}</td>
                           <td>{prop.status === 'new' && 'new'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>

          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Custom Subtitled Vlogs"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {vlogheader.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((prop, key) => {
                           let duration = new Date(0);
                           let date = new Date(prop.modified);
                           duration.setSeconds(prop.duration); 
                        
                        return (
                          prop.options.custom_subs &&
                          <tr key={key}>
                            <td>{prop.title}</td>
                           <td>{duration.toISOString().substr(14, 5)}</td>
                           <td>{date.getDate()} - {date.getMonth()} - {date.getFullYear()}</td>
                           <td>{prop.status === 'new' && 'new'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>

          </Row>
        </Grid> 
        :
        <div>No orders found.</div>
              }
            
      </div>
    );
  }
}

export default inject('dataStore')(observer(CustomVlogs));
