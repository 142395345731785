
import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import {php, videoDBbaseURL} from 'layouts/Admin.jsx';
import {observer, inject} from 'mobx-react';
import classNames from 'classnames';
import styles from './styles.module.scss';
 


class SearchBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchActive: false,
      searchValue: this.props.value
    }
    this.searchRef = React.createRef();
}

enableSearch = () => {
    // this.setState({results: this.state.dashboarda});
     this.searchRef.current.focus();
     this.props.onChange('');
     this.setState({searchActive: true, searchValue: ''});
   }
   
  setSearch = e => {
  
    this.setState({searchValue: e.target.value});
    this.props.onChange(e.target.value);
  }
  
  onBlur = e => {
   // this.setState({searchValue: ''});
  }

render(){
const searchActive = this.state.searchActive;
const searchValue = this.state.searchValue;

    return (
<div onClick={this.enableSearch}><i className="fa fa-search" />        <input
type="text"
className={classNames(styles.search, searchActive && styles.active)}
ref={this.searchRef}
value={searchValue}
onChange={this.setSearch}
onBlur={this.onBlur}
/></div>
    )
}

    }
    export default SearchBar;