import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import subsimg from "assets/subs.png"
import editimg from "assets/edit.png"

export class Orders extends Component {
  handleCheckbox = event => {
    const target = event.target;
    console.log(event.target);
    this.setState({
      [target.name]: target.checked
    });
  };
  render() {
    const edit = <Tooltip id="edit_tooltip">Edit Task</Tooltip>;
    const remove = <Tooltip id="remove_tooltip">Remove</Tooltip>;
    const orderinput = this.props.orders;
    var orders = [];
    var orderarray = [];  
    orderinput.map((prop, key) => {
      let date = new Date(prop.modified);
      orderarray.push(
        <React.Fragment>
        <td>
       {prop.options.custom_edit && <img
          width="18px"
          src={editimg}
        />}
            {prop.options.custom_subs && <img width="18px" src={subsimg} /> }
      </td><td><span><strong>{prop.title }</strong>&nbsp;<small>{date.getDate()}-{date.getMonth()}-{date.getFullYear()}</small></span></td></React.Fragment>
      )
    })

    //const orderarray = [];
  
    var number;
    for (var i = 0; i < orderarray.length; i++) {
      number = "checkbox" + i;
      orders.push(
        <tr key={i}>

        {orderarray[i]}
          { <td className="td-actions text-right">
            {/* <OverlayTrigger placement="top" overlay={edit}>
              <Button bsStyle="info" simple type="button" bsSize="xs">
                <i className="fa fa-edit" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={remove}>
              <Button bsStyle="danger" simple type="button" bsSize="xs">
                <i className="fa fa-times" />
              </Button>
            </OverlayTrigger> */}
          </td> }
        </tr>
      );
    }
    return <tbody>{orders}</tbody>;
  }
}

export default Orders;
