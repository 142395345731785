import React, { Component } from "react";

export class Card extends Component {

  openlink = url =>
  {
    window.location = url;
  }

  render() {
    return (
      <div style={{backgroundImage: `url(${this.props.bgImage})`, cursor: (this.props.link  != null ? 'pointer' : 'unset'), backgroundPosition: 'center', backgroundSize: 'cover' }} className={"card" + (this.props.plain ? " card-plain" : "" + (this.props.backgroundImage !=null ? "backgroundImage" : ""))    }
      onClick={() => (this.props.link  != null ? this.openlink(this.props.link) : null)}
      >
        <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
          <h4 className="title">{this.props.title}</h4>
          <p className="category">{this.props.category}</p>
        </div>
        <div
          className={
            "content" +
            (this.props.ctAllIcons ? " all-icons" : "") +
            (this.props.ctTableFullWidth ? " table-full-width" : "") +
            (this.props.ctTableResponsive ? " table-responsive" : "") +
            (this.props.ctTableUpgrade ? " table-upgrade" : "")
          }
        >
          {this.props.content}

          {this.props.stats != null && <div className="footer">
            {this.props.legend}
            {this.props.stats != null ? <hr /> : ""}
            <div className="stats">
              <i className={this.props.statsIcon} /> {this.props.stats}
            </div>
          </div>
  }
        </div>
      </div>
    );
  }
}

export default Card;
