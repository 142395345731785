import React, { Component } from "react";

export class UserCard extends Component {
  render() {
    return (
      <div className="card card-user">
        {/* <div className="image">
          <img src={this.props.bgImage} alt="..." />
        </div> */}
        <div className="content">
          <div className="author">
            <div style={{position: 'relative'}}>
              <img
                className="avatar border-gray"
                src={this.props.avatar}
                style={{position: 'relative'}}
              />
              <div style={{
              position: 'absolute',
              bottom: '10%',
              right: '30%',
              width: '35px',
              height: '35px'}} >
                <img 
                className="logodisplay border-gray"
              style={{position: 'absolute', width: '100%', height: '100%'}}
                src={this.props.logo}
                />
              </div>
              </div>
              <h4 className="title">
                {this.props.name}
                <br />
                <small>{this.props.userName || ''}</small>
              </h4>
          </div>
          <p className="description text-center">{this.props.description}</p>
        </div>
        {/* <hr /> */}
        <div className="text-center">{this.props.socials}</div>
      </div>
    );
  }
}

export default UserCard;
