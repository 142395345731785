import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem, Button } from "react-bootstrap";
import {videodb, _token, videoDBbaseURL} from 'layouts/Admin.jsx';
import {observer, inject} from 'mobx-react';
import classNames from 'classnames';
import styles from './styles.module.scss';
 


class AdminNavbarLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchActive: false,
      searchValue: '',
      tutorialResults: [{}],
      scriptResults: [{}],
      academyResults: [{}],
      allResults: [{}],
      data: [],
      scripts: [],
      documents: [],
      academy: [],
      tutorials: [],
      pending: false
    };
    this.baseState = this.state 
    this.searchRef = React.createRef();
  }

  componentDidMount(){
    this.props.dataStore.getData()
    .then(() => {
      this.setState({documents: this.props.dataStore.data.documents});
      this.setState({scripts: this.props.dataStore.data.scripts});
      this.setState({academy: this.props.dataStore.data.academy});
      this.setState({tutorials: this.props.dataStore.data.tutorials});
      console.log('set');
    });
  }

  useSearch = () => {
    if(this.state.searchActive){
      this.enableSearch();
    }else{
      this.disableSearch();
    }
  }

  enableSearch = () => {
   // this.setState({results: this.state.dashboarda});
    this.searchRef.current.focus();
    this.setState({searchActive: true, searchValue: ''});
  }

  onBlur = () => {
    setTimeout(this.disableSearch, 85);
  }

  disableSearch = () => {
    this.setState({searchActive: false, searchValue: ''});
  }


  refreshDashboard = () => {
    this.setState({pending: true});
    videodb.get('/dashboard?update=true', {update: true})
    .then(() => 
    {
      this.setState(this.baseState);
    }
    );
  }

  //setSearch = e => this.setState({searchValue: e.target.value});

  setSearchVal = e => {

    this.setState({searchValue: e.target.value});
    this.props.dataStore.setFilterString(e.target.value);
  }

  setSearch = e => {
    this.setState({ allResults: []});
   this.setState({searchValue: e.target.value});
   this.setState({scriptResults: this.state.scripts.filter(scripts => scripts.title.toLowerCase().includes(e.target.value.toLowerCase()))});
   this.state.tutorials.map(categories => this.setState({tutorialResults : [{ [categories.category] : categories.content.filter(tutorials => tutorials.title.includes(e.target.value.toLowerCase())) }] }));
   //this.state.videodb.map(categories => this.setState({academyResults : [{ [categories.category] : categories.content.filter(tutorials => tutorials.title.includes(e.target.value.toLowerCase())) }] }));

  //  console.log('TUTS');
  //  this.state.tutorialResults.map(res => {
  //   this.setState({ allResults: [...this.state.allResults, res] });
  //  });
   console.log('Scripts');
   this.state.scriptResults.map(res => {
    this.setState({ allResults: [...this.state.allResults, res] });
   });
  //  console.log('Academy');
  //  this.state.academyResults.map(res => {
  //   this.setState({ allResults: [...this.state.allResults, res] });
  //  });
  }

  renderResults = () =>
  {
    return (
    this.state.allResults.map(results => {
      console.log(results.title);
      return (
      <li><a href="#">{results.title}</a></li>
    )}
      )
    )
  }

  //renderResults = () => <li><a href="#">AAp</a></li>

  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">5</span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );
    const searchValue = this.state.searchValue;
    const searchActive = this.state.searchActive;
    const {pending, results} = this.state;
    const files =  this.state.searchValue
    ? this.state.data.filter(vlog => vlog.title.toLowerCase().includes(this.state.searchValue.toLowerCase()))
    : this.state.data;
    return (
      <div>
        <Nav style={{margin: '10px'}}>
          <NavItem eventKey={1} href="/admin/dashboard">
            <i className="fa fa-dashboard" />
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>
          {/* <NavDropdown
            eventKey={2}
            title={notification}
            noCaret
            id="basic-nav-dropdown"
          >
            <MenuItem eventKey={2.1}>Notification 1</MenuItem>
            <MenuItem eventKey={2.2}>Notification 2</MenuItem>
            <MenuItem eventKey={2.3}>Notification 3</MenuItem>
            <MenuItem eventKey={2.4}>Notification 4</MenuItem>
            <MenuItem eventKey={2.5}>Another notifications</MenuItem>
          </NavDropdown> */}
          {/* <NavItem eventKey={3} onClick={this.enableSearch}>
            <i className="fa fa-search" />
            <p className="hidden-lg hidden-md">Search</p>
            <p>{files}</p>
          </NavItem> */}
          {/* <NavItem className={classNames(!searchActive && styles.iteminactive)}>

<div className="searchfield">
<input
type="text"
className={classNames(styles.search, searchActive && styles.active)}
ref={this.searchRef}
value={searchValue}
onChange={this.setSearchVal}
onBlur={this.onBlur}
onfocusout={this.onBlure}
/>
</div>



          </NavItem> */}
          <NavItem eventKey={3} onClick={this.refreshDashboard}>
            {pending ? <i className={classNames("fa fa-refresh", styles.rotate)} />
            : 
            <i className="fa fa-refresh" />
        }
          </NavItem>
        </Nav>

        <Nav pullRight>
          <NavItem eventKey={1} target="_blank" href="//userdb.vlogahead.cloud">
          <Button style={{margin: 0}} variant="primary">Account Admin</Button>
          </NavItem>
          <NavItem eventKey={2} target="_blank" href={`https://app.vlogahead.com/#/?t=${_token}`}>
          <Button style={{margin: 0}} variant="primary">Web App</Button>
          </NavItem>
          {/* <NavDropdown
            eventKey={2}
            title="Dropdown"
            id="basic-nav-dropdown-right"
          >
            <MenuItem eventKey={2.1}>Action</MenuItem>
            <MenuItem eventKey={2.2}>Another action</MenuItem>
            <MenuItem eventKey={2.3}>Something</MenuItem>
            <MenuItem eventKey={2.4}>Another action</MenuItem>
            <MenuItem eventKey={2.5}>Something</MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={2.5}>Separated link</MenuItem>
          </NavDropdown> */}
          <NavItem eventKey={3} href="/admin/logout">
          <Button style={{margin: 0, border: 'none'}} block>Log out</Button>  
          </NavItem>
        </Nav>
      </div>
    );
  }
}
export default inject('dataStore')(observer(AdminNavbarLinks));
