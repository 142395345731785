import React, { Component } from "react";
import { Table, Grid, Row, Col } from "react-bootstrap";
import printJS from 'print-js'
import {observer, inject} from 'mobx-react';
import Card from "components/Card/Card";
import {isEmpty} from "lodash-es"
import SearchBar from "components/SearchBar/SearchBar";
import {php, videoDBbaseURL} from 'layouts/Admin.jsx';
import Button from "components/CustomButton/CustomButton";
import classNames from 'classnames';
import styles from './viewstyles.module.scss';
import Spinner from "components/Spinner/index.js"
import scriptimg from "assets/script.png"


class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scripts: [],
      documents: [],
      customscripts: [],
      searchValue: false,
      loading: true
    };
  }

  componentDidMount(){
    this.props.dataStore.getData().then(() => {
      this.setState({scripts: this.props.dataStore.data.scripts});
      this.setState({customScripts: this.props.dataStore.data.customScripts});
      this.setState({documents: this.props.dataStore.data.documents});
      this.setState({loading: false});
    }
    );
}


setSearch = value => this.setState({searchValue: value});


  render() {
    let scripts = this.state.scripts.map(script => { 
      this.state.searchValue ?
      script.active = script.title.toLowerCase().includes(this.state.searchValue.toLowerCase())
      :
      script.active = true;
      return (
       script
      
      
      //script.active = 
      
      ) });
      let documents = this.state.documents.map(document => {
        this.state.searchValue ?
        document.active = document.title.toLowerCase().includes(this.state.searchValue.toLowerCase())
        :
        document.active = true;
        return (
         document
        
        
        //script.active = 
        ) });
        
      
    let customScripts = this.state.searchValue
    ? this.state.customScripts.filter(script => script.title.toLowerCase().includes(this.state.searchValue.toLowerCase()))
    : this.state.customScripts;

    const searchValue = this.state.searchValue;

    return (
      <div className="content">
        {this.state.loading ? <Spinner small /> : 
           <Grid fluid>  <Col md={12}>
<SearchBar onChange={this.setSearch} value={searchValue} />
</Col>
           <Col md={12} style={{paddingTop: '10px'}}>
           <Card 
           
          category="Documents"
          ctAllIcons
           content={
           <Row className={styles.allitems}>
               {documents.map((prop, key) => {
                 let propactive = prop.active;
                 return(
          <Col            lg={2}
                          md={3}
                          sm={4}
                          xs={6}
                          className={classNames("font-icon-list", styles.searchitem, !propactive && styles.inactive)}
                          key={key}
                          >

              <div 
                    className="font-icon-detail">
                    <i style={{width:`100%`}} className='pe-7s-file' />
                    <div className="small"><br />{prop.title.split('_').join(' ')}<br /></div>
                     <Button style={{padding: `10px`}} simple type="button" bsSize="xs" onClick={() => printJS({printable: `${prop.link}`, type:'pdf', showModal:true })}>Print</Button>&nbsp;
                     <Button style={{padding: `10px`}} simple type="button" bsSize="xs" onClick={() => window.open(prop.link)}>Open</Button>
                  </div>
                  

            </Col>
                ) })}
         
            </Row>
          }
          />
          <Card 
          
          category={customScripts ? "Template Scripts" : ""}
          ctAllIcons
           content={
           <Row className={styles.allitems}>
               {scripts.map((prop, key) => {
                 let propactive = prop.active;
                 return(
          <Col            lg={2}
                          md={3}
                          sm={4}
                          xs={6}
                          className={classNames("font-icon-list", styles.searchitem, !propactive && styles.inactive)}
                          key={key}
                          >

                 <div 
                    className="font-icon-detail">
                      <img src={scriptimg} style={{width:`40px`}} />
                    <div className="small"><br />{prop.title}<br /></div>
                     <Button style={{padding: `10px`}} simple type="button" bsSize="xs" onClick={() => printJS({printable: `${prop.link}`, type:'pdf', showModal:true })}>Print</Button>&nbsp;
                     <Button style={{padding: `10px`}} simple type="button" bsSize="xs" onClick={() => window.open(prop.link)}>Open</Button>
                  </div>

            </Col>
                ) })}
         
            </Row>
          }
          />
                 {!isEmpty(customScripts) && <Card 
                 
          category="Custom Scripts"
          ctAllIcons
           content={
           <Row>
                            {customScripts.map((prop, key) => {
                 return(
          <Col            lg={2}
                          md={3}
                          sm={4}
                          xs={6}
                          className="font-icon-list"
                          key={key}>
         
         <div
                    className="font-icon-detail">
                      <img src={scriptimg} style={{width:`40px`}} />
                    <div className="small"><br />{prop.title}<br /></div>

                    <Button style={{padding: `10px`}} simple type="button" bsSize="xs" onClick={() => printJS({printable: `${prop.link}`, type:'pdf', showModal:true })}>Print</Button>&nbsp;
                     <Button style={{padding: `10px`}} simple type="button" bsSize="xs" onClick={() => window.open(prop.link)}>Open</Button>

                  </div>

            </Col>
                 )
                            })}
         
            </Row>
          }
          />}
            </Col>
            </Grid>
            }
                    {/* <Grid fluid>
          <Row>
            <Col md={8} mdOffset={2}>
              <Card
                hCenter
                title="Light Bootstrap Dashboard PRO React"
                category="Are you looking for more components? Please check our Premium Version of Light Bootstrap Dashboard React."
                ctTableResponsive
                ctTableFullWidth
                ctTableUpgrade
                content={
                  <Table>
                    <thead>
                      <tr>
                        <th />
                        <th className="text-center">Free</th>
                        <th className="text-center">PRO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Components</td>
                        <td>30</td>
                        <td>60</td>
                      </tr>
                      <tr>
                        <td>Plugins</td>
                        <td>3</td>
                        <td>13</td>
                      </tr>
                      <tr>
                        <td>Example Pages</td>
                        <td>7</td>
                        <td>24</td>
                      </tr>
                      <tr>
                        <td>Login/Register/Lock Pages</td>
                        <td>
                          <i className="fa fa-times text-danger" />
                        </td>
                        <td>
                          <i className="fa fa-check text-success" />
                        </td>
                      </tr>
                      <tr>
                        <td>Premium Support</td>
                        <td>
                          <i className="fa fa-times text-danger" />
                        </td>
                        <td>
                          <i className="fa fa-check text-success" />
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>Free</td>
                        <td>Just $49</td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <Button
                            href="#"
                            round
                            fill
                            disabled
                            bsStyle="default"
                          >
                            Current Version
                          </Button>
                        </td>
                        <td>
                          <Button
                            target="_blank"
                            href="http://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react/?ref=lbdr-upgrade-page"
                            round
                            fill
                            bsStyle="info"
                          >
                            Upgrade to PRO
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid> */}
      
      </div>
    );
  }
}

export default inject('dataStore')(observer(Documents));
