import React, { Component } from "react";
import Cookies from 'js-cookie';
import {videodb, videoDBbaseURL} from 'layouts/Admin.jsx';

class Logout extends Component {

    componentDidMount(){
        videodb.get('/logout').then(() => this.logout());
    }

    logout = () => videodb.get('/logout')
    .then(() => {
      this.clearToken();
      window.location.href = "/admin/login";
    })
  
    clearToken = () => {
      this.token = null;
      try {
        window.localStorage.removeItem('token', null);
      } catch (e) {
        Cookies.remove('token');
      }
    }

  render() {
    return (
      <div className="content">
        
      </div>
    );
  }
}

export default Logout;

