import React, { Component, createRef } from "react";
import { Table, Grid, Row, Col } from "react-bootstrap";
import {observer, inject} from 'mobx-react';
import Card from "components/Card/Card";
import {isEmpty} from "lodash-es"
import {php, videoDBbaseURL} from 'layouts/Admin.jsx';
import Button from "components/CustomButton/CustomButton";
import classNames from 'classnames';
import styles from './viewstyles.module.scss';
import Spinner from "components/Spinner/index.js"
import ReactImageVideoLightbox from "react-image-video-lightbox";
import SearchBar from "components/SearchBar/SearchBar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";

class Tutorials extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      linkdata: [],
      searchValue: '',
      loading: true,
      lightboxOpen: false,
      url: null,
      index: 0
    };
    this.box = React.createRef();
    this.linkdata = [];
    this.counter = 0;
  }

  componentDidMount(){
    this.props.dataStore.getData().then(() => {
      this.setState({data: this.props.dataStore.data.tutorials});
      this.setState({loading: false});
    });
  }

  showVideo = url => {
    return(
    // <SRLWrapper>
    //   <video controls>
    //     <source src={url} type="video/mp4"/>
    //   </video>
    //   </SRLWrapper>
    <ReactImageVideoLightbox
    data={[
        { url: url, type: 'video', altTag: 'some image' }
    ]}
    startIndex={0}
    showResourceCount={false}
    />
    )
    }
  
  
  setSearch = value => this.setState({searchValue: value});


  parseTime = seconds => {
    let date = new Date(0);
    date.setSeconds(seconds);
    if(seconds >= 3600)  return date.toISOString().substr(11, 8);
    return date.toISOString().substr(14, 5);
  }

  render() {
    const data = this.state.searchValue
    ? this.state.data.map(categories => {
       return ( {category: categories.category, content: categories.content.filter(tutorials => tutorials.title.toLowerCase().includes(this.state.searchValue.toLowerCase()))})
    })
    : this.state.data;
    //let node = this.box.current;
    
  //  this.state.lightboxOpen && setTimeout(() => { let node = document.getElementById("box"); node.scrollIntoView()} , 2000);
    
    //this.state.scripts.filter(script => script.title.toLowerCase().includes(this.state.searchValue.toLowerCase()))
    return (
      <div className="content" style={{overflow: this.state.lightboxOpen && 'hidden', maxHeight: this.state.lightboxOpen && '60vh'}}>      
        {
           this.state.lightboxOpen &&
           <div ref={this.box} style={{overflowY: 'hidden', zIndex: 100, position: 'fixed', left: 0, top: 0, height: '100vh', width: '99.2vw'}}>
           <ReactImageVideoLightbox
           //  data={[
           //    { url: this.state.url, type: 'video' } ]}
             data = {this.state.linkdata}
             startIndex={this.state.index}
             onCloseCallback={() => { this.setState({ lightboxOpen: false, linkdata: [] }); }} />
             </div>
             

        }
        
           { this.state.loading ? <Spinner small /> : <Grid fluid>
            <Col md={12}>
<SearchBar onChange={this.setSearch} value={this.state.searchValue} />
</Col>
             {data.map((prop, key) => {
               if(isEmpty(this.linkdata[key])) { this.linkdata[key] = []; }
               return (
                prop.content.length ?
           <Col key={key}  xs={12} md={12}>
          <Card plain ctTableFullWidth
          category={<strong>{prop.category.split('_').join(' ').replace(/[0-9]/g, "")}</strong>}
           content={
            prop.content.map((tutorial, index) => {
            //  this.linkdata = [...this.linkdata.key, {url: tutorial.link, type: 'video'}];
            this.linkdata[key] = [...this.linkdata[key], {url: tutorial.link, type: 'video'} ]
              let row = (4 - Math.floor(4)) !== 0; //4 rows
              return (
          <Col
          md={3}
          xs={6} key={index}>
              <Card
                statsIcon="fa fa-clock-o"
                category={tutorial.title.split('_').join(' ').replace(/[0-9]/g, "")}
                stats={this.parseTime(tutorial.duration)}
                content={
                  <div onClick={() => {  this.setState({ lightboxOpen: true, url: tutorial.link, linkdata: this.linkdata[key], index: index });  }}>
                  <div
                    className="ct-perfect-fourth"
                  >
                    <img style={{width:`100%`}} alt={`${tutorial.title}`} src={tutorial.thumb}></img>
                    <div className="small"><br />{tutorial.description}</div>
                  </div>
                  </div>
                }

              />     
            </Col>
              )

            })
          }
        
          />
            </Col>
            : 
            ''
               )
              
        }
             )
      }
             
            </Grid> }
        {/* <Grid fluid>
          <Row>
            <Col md={8} mdOffset={2}>
              <Card
                hCenter
                title="Light Bootstrap Dashboard PRO React"
                category="Are you looking for more components? Please check our Premium Version of Light Bootstrap Dashboard React."
                ctTableResponsive
                ctTableFullWidth
                ctTableUpgrade
                content={
                  <Table>
                    <thead>
                      <tr>
                        <th />
                        <th className="text-center">Free</th>
                        <th className="text-center">PRO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Components</td>
                        <td>30</td>
                        <td>60</td>
                      </tr>
                      <tr>
                        <td>Plugins</td>
                        <td>3</td>
                        <td>13</td>
                      </tr>
                      <tr>
                        <td>Example Pages</td>
                        <td>7</td>
                        <td>24</td>
                      </tr>
                      <tr>
                        <td>Login/Register/Lock Pages</td>
                        <td>
                          <i className="fa fa-times text-danger" />
                        </td>
                        <td>
                          <i className="fa fa-check text-success" />
                        </td>
                      </tr>
                      <tr>
                        <td>Premium Support</td>
                        <td>
                          <i className="fa fa-times text-danger" />
                        </td>
                        <td>
                          <i className="fa fa-check text-success" />
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>Free</td>
                        <td>Just $49</td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <Button
                            href="#"
                            round
                            fill
                            disabled
                            bsStyle="default"
                          >
                            Current Version
                          </Button>
                        </td>
                        <td>
                          <Button
                            target="_blank"
                            href="http://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react/?ref=lbdr-upgrade-page"
                            round
                            fill
                            bsStyle="info"
                          >
                            Upgrade to PRO
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid> */}
      </div>
    );
  }
}

export default inject('dataStore')(observer(Tutorials));
