import React, {Component} from 'react';
import FontAwesome from 'react-fontawesome';
import './styles.scss';

import classNames from 'classnames';

export default class Spinner extends Component {

  render() {
    const {small, medium} = this.props;
    if(small){
      return (
        <div className="smallcontainer">
          <FontAwesome className="smallspinner dospin" name="spinner"/>
        </div>
      )
    }
    else if(medium){
      return (
        <div className="smallcontainer">
          <FontAwesome className="mediumspinner dospin" name="spinner"/>
        </div>
      )
    }
    else{
      return(
        <div className="container">
          <FontAwesome className="spinner" name="spinner"/>
        </div>
      )
    }
  }
}
