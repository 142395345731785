import React, { Component } from "react";
import { Table, Grid, Row, Col } from "react-bootstrap";
import {observer, inject} from 'mobx-react';
import Card from "components/Card/Card";
import {isEmpty} from "lodash-es";
import {php, videodb, videoDBbaseURL} from 'layouts/Admin.jsx';
import Spinner from "components/Spinner/index.js"
import Button from "components/CustomButton/CustomButton";
import classNames from 'classnames';
import styles from './viewstyles.module.scss';
import SearchBar from "components/SearchBar/SearchBar";
import ReactImageVideoLightbox from "react-image-video-lightbox";


class Academy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      linkdata: [],
      searchValue: false,
      loading: true,
      lightboxOpen: false,
      url: null,
      index: 0
    };
     this.linkdata = [];

  }

  componentDidMount(){
    this.props.dataStore.getData().then(() => {
      this.setState({data: this.props.dataStore.data.academy});
      this.setState({loading: false});
    });
  }

  setSearch = value => this.setState({searchValue: value});
  

  parseTime = seconds => {
    let date = new Date(0);
    date.setSeconds(seconds);
    if(seconds >= 3600)  return date.toISOString().substr(11, 8);
    return date.toISOString().substr(14, 5) || 0;
  }

  rendercard =(tutorial, index, key) => {
    this.linkdata[key] = [...this.linkdata[key], {url: tutorial.link, type: 'video'} ]
    return (
    <Col md={3} xs={6}  key={index}>
        <Card
          statsIcon="fa fa-clock-o"
          category={tutorial.title.split('_').join(' ').replace(/[0-9]/g, "")}
          stats={this.parseTime(tutorial.duration)}
          content={
            <div onClick={() => {  this.setState({ lightboxOpen: true, url: tutorial.link, linkdata: this.linkdata[key], index: index });  }}>
            <div
              className="ct-perfect-fourth"
            >
              <img style={{width:`100%`}} alt={`${tutorial.title}`} src={tutorial.thumb}></img>
              <div className="small"><br />{tutorial.description}</div>
            </div>
            </div>
          }

        />     
      </Col>
    )
  }


  render() {
    const data = this.state.searchValue
    ? this.state.data.map(categories => {
       return ( {category: categories.category, content: categories.content.filter(tutorials => (
         !tutorials.category
         ? tutorials.title.toLowerCase().includes(this.state.searchValue.toLowerCase())
         : tutorials.content.filter(tutorials2 => tutorials2.title.toLowerCase().includes(this.state.searchValue.toLowerCase())).includes(this.state.searchValue.toLowerCase())


         )  )})
    })
    : this.state.data;
       //this.state.videodb.map(categories => this.setState({academyResults : [{ [categories.category] : categories.content.filter(tutorials => tutorials.title.includes(e.target.value.toLowerCase())) }] }));

    const searchValue = this.state.searchValue;
    const title = category => <span><strong>{category.split('_').join(' ').replace(/[0-9]/g, "")} </strong></span>
    return (  
      this.state.loading ? <Spinner small /> :
      <div className="content">
                {
           this.state.lightboxOpen &&
           <div style={{overflowY: 'hidden', zIndex: 100, position: 'fixed', left: 0, top: 0, height: '100vh', width: '99.2vw'}}>
           <ReactImageVideoLightbox
           //  data={[
           //    { url: this.state.url, type: 'video' } ]}
             data = {this.state.linkdata}
             startIndex={this.state.index}
             onCloseCallback={() => { this.setState({ lightboxOpen: false }); }} />
             </div>
        }
           <Grid fluid>
           <Col md={12}>
<SearchBar onChange={this.setSearch} value={searchValue} />
</Col>
             {data.map((prop, key) => {
                   if(isEmpty(this.linkdata[key])) { this.linkdata[key] = []; }
               return (
           <Col key={key} md={12}>
          <Card plain ctTableFullWidth
          category={title(prop.category)}
           content={
            prop.content.length ?
            prop.content.map((tutorial, index) => 
            !tutorial.category ?
            this.rendercard(tutorial, index, key)
            :
            tutorial.content.length ?
            <Col key={key} md={12}>
            <Card plain ctTableFullWidth
             category={tutorial.category.split('_').join(' ').replace(/[0-9]/g, "")}
             content={
               tutorial.content.map((tutorial2, index2) => 
                this.rendercard(tutorial2, index2, index)
               )
             }
             /></Col>
             :
             ''
            )
            : 
            ''
          }
          />
            </Col>
               )
        }
             )
      }
             
            </Grid>
        {/* <Grid fluid>
          <Row>
            <Col md={8} mdOffset={2}>
              <Card
                hCenter
                title="Light Bootstrap Dashboard PRO React"
                category="Are you looking for more components? Please check our Premium Version of Light Bootstrap Dashboard React."
                ctTableResponsive
                ctTableFullWidth
                ctTableUpgrade
                content={
                  <Table>
                    <thead>
                      <tr>
                        <th />
                        <th className="text-center">Free</th>
                        <th className="text-center">PRO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Components</td>
                        <td>30</td>
                        <td>60</td>
                      </tr>
                      <tr>
                        <td>Plugins</td>
                        <td>3</td>
                        <td>13</td>
                      </tr>
                      <tr>
                        <td>Example Pages</td>
                        <td>7</td>
                        <td>24</td>
                      </tr>
                      <tr>
                        <td>Login/Register/Lock Pages</td>
                        <td>
                          <i className="fa fa-times text-danger" />
                        </td>
                        <td>
                          <i className="fa fa-check text-success" />
                        </td>
                      </tr>
                      <tr>
                        <td>Premium Support</td>
                        <td>
                          <i className="fa fa-times text-danger" />
                        </td>
                        <td>
                          <i className="fa fa-check text-success" />
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>Free</td>
                        <td>Just $49</td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <Button
                            href="#"
                            round
                            fill
                            disabled
                            bsStyle="default"
                          >
                            Current Version
                          </Button>
                        </td>
                        <td>
                          <Button
                            target="_blank"
                            href="http://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react/?ref=lbdr-upgrade-page"
                            round
                            fill
                            bsStyle="info"
                          >
                            Upgrade to PRO
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid> */}
      </div>
      
    );
  }
}

export default inject('dataStore')(observer(Academy));
